import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import customer from "../../assets/img/onur-afsar.png"
import mockup from "../../assets/img/kentpark-mockup.png"

import detail1 from "../../assets/img/kentpark-detail1.png"
import detail2 from "../../assets/img/kentpark-detail2.png"
import detail3 from "../../assets/img/kentpark-detail3.png"

import OtherCaseStudy from "../../components/other-case"

import eelbeyglu from "../../assets/img/teams/11.jpg"
import serife from "../../assets/img/teams/20.jpg"
import tugce from "../../assets/img/teams/03.png"
import melisa from "../../assets/img/teams/18.jpg"

import video1 from "../../assets/img/kentpark-video1.mp4"
import video2 from "../../assets/img/kentpark-video2.mp4"


const ReferancesDetail = () => (
    <Layout>
        <SEO title="Kentpark Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Kentpark AVM</h1>
                <p>Ankara’nın en popüler alışveriş merkezi Kentpark Alışveriş Merkezi ile 2015 Şubat ayında başlayan iş birliğimiz kapsamında sosyal medya yönetimi alanında onlarca proje hayata geçirildi.</p>
                <p>Sosyal medya yönetimi sürecinde Kentpark Alışveriş Merkezi dijital platformlarda “Genç, dinamik ve yenilikçi alışveriş merkezi” olarak konumlandırıldı. Facebook, Instagram, Twitter, Snapchat ve Vine gibi sosyal medya platformlarında @kentparkavm kullanıcı adıyla hedef kitlesine ulaşan Kentpark Alışveriş Merkezi için yapılan sosyal medya içerik geliştirme operasyonları, projeler ve kampanyalar ile tüm platformlarda başarılı bir grafik yakalandı.</p>
                <p>Ana mecralar arasında yer alan Facebook’ta %125 oranında beğeni artışı sağlanırken Instagram’daki takipçi sayısı 13 katına çıkarıldı. Kentpark AVM,  tüm dijital platformlarda halihazırda ayda ortalama 1.000.000 kişiye erişim sağlıyor.</p>

            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs">
                    <div className="box">
                        <span>%125</span>
                        <p>Instagram takipçi <br /> artışı</p>
                    </div>
                    <div className="box">
                        <span>+6m</span>
                        <p>Aylık erişilen<br />
                            kullanıcı sayısı.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 101 101"><g id="Icons-_-Code-_-Code" data-name="Icons-/-Code-/-Code" transform="translate(-0.115 -0.115)"><rect id="bound" width="101" height="101" transform="translate(0.115 0.115)" fill="none"></rect><path id="Combined-Shape" d="M69.579,14.1a4.215,4.215,0,0,1,6.138-5.777L92.576,26.238a4.215,4.215,0,0,1,0,5.777L75.717,49.927a4.215,4.215,0,1,1-6.138-5.777l14.14-15.024Zm-44.437,0L11,29.127,25.142,44.15A4.215,4.215,0,1,1,19,49.927L2.146,32.015a4.215,4.215,0,0,1,0-5.777L19,8.326A4.215,4.215,0,1,1,25.142,14.1Z" transform="translate(3.179 22.466)"></path><rect id="Rectangle-28" width="8.429" height="67.434" rx="1" transform="matrix(0.966, 0.259, -0.259, 0.966, 55.195, 16.881)" opacity="0.3"></rect></g></svg>
                            Website Yönetimi
                        </div>
                        <div className="box">
                            <Link to="/sosyal-medya/"></Link>
                            <svg id="instagram-logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 61.312 61.312">
                                <path id="Path_36346" data-name="Path 36346" d="M44.392,0H16.92A16.939,16.939,0,0,0,0,16.92V44.392a16.939,16.939,0,0,0,16.92,16.92H44.392a16.939,16.939,0,0,0,16.92-16.92V16.92A16.939,16.939,0,0,0,44.392,0ZM55.872,44.392a11.493,11.493,0,0,1-11.48,11.48H16.92A11.493,11.493,0,0,1,5.44,44.392V16.92A11.493,11.493,0,0,1,16.92,5.44H44.392a11.493,11.493,0,0,1,11.48,11.48V44.392Z" />
                                <path id="Path_36347" data-name="Path 36347" d="M56.767,40.97a15.8,15.8,0,1,0,15.8,15.8A15.816,15.816,0,0,0,56.767,40.97Zm0,26.156A10.358,10.358,0,1,1,67.125,56.768,10.37,10.37,0,0,1,56.767,67.126Z" transform="translate(-26.111 -26.112)" />
                                <path id="Path_36348" data-name="Path 36348" d="M122.91,28.251a3.987,3.987,0,1,0,2.821,1.168A4,4,0,0,0,122.91,28.251Z" transform="translate(-75.793 -18.005)" />
                            </svg>
                            Sosyal Medya
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Yöneticisi:</li>
                            <li>Sosyal Medya Yöneticisi:</li>
                            <li>Grafik Tasarımcı:</li>
                            <li>Website Yönetim:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa} />Melisa Balyemez</li>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu" />Emre Elbeyoğlu</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={tugce} alt="Tuğçe Gör" />Tuğçe Gör</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div className="testimonial-customer" id="one">
                <p>“Flatart imzalı websitemiz ile ödül alarak küresel arenada yankı uyandırdık. En önemlisi, müşterilerimizin beğenisini kazanan bir websitesine sahip olduk.”</p>
                <div className="author">
                    <div>
                        {/* <img src={customer}></img> */}
                    </div>
                    <div className="author-name">
                        Necla Türbay<br />
                        <span>Pazarlama Müdürü</span>
                    </div>
                </div>
            </div>




            <div className="customer-content pt60">
                <h2>Mannequin Challange<br />Kampanyası</h2>

                <p>‘‘Obama yapar da biz durur muyuz! Ekipçe donduk kaldık. :)’’<br /><br />Mannequin Challenge videoları milyonlara ulaşırken Bobbi Brown markası ile birlikte Kentpark AVM için #mannequinchallange etiketli video içeriği hazırlandı. Bu konseptle yapılan ilk sosyal medya yarışma kurgusu özelliğine sahip olan yarışmaya, videoda hareket eden hediyenin ekran görüntüsünü yakalayıp Instagram hesabında paylaşan 380 takipçi katıldı.</p>

                <video width="70%" controls style={{ margin: '30px auto' }}>
                    <source src={video1} type="video/mp4" />
                </video>

                <h2>Sıradışı bir tasarım, <br/>global ödüllü bir websitesi.</h2>
                <p>Kentpark AVM’nin arama odaklı, sade, interaktif ve bu özelliklerinden dolayı Global Websites Awards’dan ödüllü websitesi, 2 yılı aşkın süredir kullanıcıların erişmek istediği veriye en kolay şekilde ulaşmasını sağlıyor.</p>


                <div className="site-preview">
                    <div className="mockup">
                        <img src={mockup} />
                    </div>
                </div>
                <h2>“Peki ya Baban?”<br />
                    Kentpark AVM Babalar Günü<br />
                    Özel Kampanyası</h2>
                <img style={{ width: '50%', margin: '20px auto' }} src={detail2} />

                <p>“Çiçekler, böcekler, hediyeler annelere… Peki ya baban?? ”
                    <br /><br />
                    Anneler Günü’ne gösterilen özenin yanında Babalar Günü’nün yeterince önemsenmediğinin mizahi bir dille anlatıldığı proje, Anneler Günü infografik görsellerinin yer verildiği teaser video yayını ile başladı. Kullanıcılar Babalar Günü’nü unutmadıklarını anlatan içerik ve fotoğrafları #pekiyababan hashtag’i ile sosyal medya hesaplarında paylaşarak yarışmaya katıldılar.
</p>
                <p>Yarışma sonucunda 285 katılımcı hediye kazandı, böylece babasını da unutmadığını göstermiş oldu.
                <br /><br />
                    “Babalar güldü, biz de güldük. 🙂”</p>
                <video width="100%" controls style={{ margin: '30px auto' }}>
                    <source src={video2} type="video/mp4" />
                </video>

            </div>

        </div>



        <OtherCaseStudy />
        <Checkup />
    </Layout >
)

export default ReferancesDetail
